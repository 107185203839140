body, #root, html {
  margin: 0;
  color: hsl(240, 10%, 35%);
  font-size: 18px;
  font-family: 'Metropolis-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: 'iA-Writer-Regular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'iA-Writer-Regular';
  src: local('iA-Writer-Regular'), url(./styles/fontFiles/ia-writer-duospace/iAWriterDuospace-Regular.otf) format('OpenType');
}

@font-face {
  font-family: 'iA-Writer-Bold';
  src: local('iA-Writer-Bold'), url(./styles/fontFiles/ia-writer-duospace/iAWriterDuospace-Bold.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-Black';
  src: local('Metropolis-Black'), url(./styles/fontFiles/metropolis/Metropolis-Black.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-ExtraBold';
  src: local('Metropolis-ExtraBold'), url(./styles/fontFiles/metropolis/Metropolis-ExtraBold.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-Regular';
  src: local('Metropolis-Regular'), url(./styles/fontFiles/metropolis/Metropolis-Regular.otf) format('OpenType');
}

@font-face {
  font-family: 'Metropolis-Medium';
  src: local('Metropolis-Medium'), url(./styles/fontFiles/metropolis/Metropolis-Medium.otf) format('OpenType');
}